export default class Menu {
    constructor() {
        const header = document.getElementById('site-header');

        if(header === null) return;
        
        window.addEventListener('scroll', () => {
            const y = window.pageYOffset;
    
            if (y > 400) {
                header.classList.add('is-fixed');
    
            } else {
                header.classList.remove('is-fixed');
            }
        });


        const trigger = document.getElementById('mobile-menu-trigger');
        const overlay = document.querySelector('.menu-overlay');

        const menu = document.querySelector('.max-mega-menu');
        if(menu === null) return;
        if(trigger === null) return;
        if(overlay === null) return;

        const close = () => {
            menu.classList.remove('is-active');
            trigger.classList.remove('is-active');
            document.documentElement.classList.remove('menu-open');
            overlay.classList.remove('is-active');
        }

        trigger.addEventListener('click', () => {
            menu.classList.toggle('is-active');
            trigger.classList.toggle('is-active');
            document.documentElement.classList.toggle('has-menu-open');
            overlay.classList.toggle('is-active');
        });

        overlay.addEventListener('click', () => {
            close();
        });
    }
}