import '@styles/main.scss';
import '@styles/common/tailwind.css';

import ActivitiesSwiper from './components/activities-swiper';
import Menu from './components/menu';
import Swiper, {Navigation} from 'swiper';
import 'swiper/css';
import EventSwiper from './components/events-swiper';
import CookieAccept from './cookie-accept';

class App {
    constructor() {
        new ActivitiesSwiper();
        new Menu();
        new EventSwiper();

        const text = 'This website uses cookies to ensure you get the best experience on our website. By accepting you agree to allow us to use cookies',
        cookieAccept = new CookieAccept(text);
 
         if (cookieAccept.shouldShow()) {
             cookieAccept.show();
         }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
    
});
