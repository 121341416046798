import Swiper, {Navigation} from 'swiper';

import 'swiper/css';

export default class EventSwiper {
    constructor() {

        function addBackgroundClass(swiper) {
            var slides = swiper.slides;
          
            slides.forEach(function(slide, index) {
              var slideIndex = parseInt(slide.getAttribute('data-swiper-slide-index'), 10);
              var colorClass = '';
          
              if (slideIndex % 3 === 0) {
                colorClass = 'is-blue';
              } else if ((slideIndex - 1) % 3 === 0) {
                colorClass = 'is-purple';
              } else if ((slideIndex - 2) % 3 === 0) {
                colorClass = 'is-green';
              }
          
              slide.classList.add(colorClass);
            });
          }

        const wrapper = document.querySelectorAll('.event-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const eventSwiper = new Swiper('.events-swiper', {
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },

                slidesPerView: 3,
                spaceBetween: 80,
                loop: true,
                // watchOverflow: true,

                on: {
                    init: function() {
                        addBackgroundClass(this);
                    },
                  },

                modules: [Navigation],

            })
        })
    }
}