import { h, setCookie } from '../util/dom-utils';

function hHTML(tag, classes, innerHTML = '') {
    const el = document.createElement(tag);
    el.className = classes;
	el.innerHTML = innerHTML;
    return el;
}

/**
 * A class for promting visitors about the site's cookie policy.
 */
export default class CookieAccept {

	/**
	 * Creates a new Cookie accept object.
	 * 
	 * @param {string} message The cookie acceptance message. This can include HTML. 
	 * @param {*} options Options to use for this cookie accept instance.
	 */
	constructor(message, options = {}) {

		this.message = message;

		const defaultOptions = {
			cookieName: 'accept_cookies', // name of the cookie to set
			days: 360, // amount of days the cookie lasts for
		};

		this.options = {
			...defaultOptions,
			...options,
		};
	}

	init() {
		if (this.shouldShow()) {
			this.show();
		}
	}

	/**
	 * Check if cookies have been accepted.
	 */
	shouldShow() {
		return document.cookie.indexOf(this.options.cookieName) === -1; // check cookies does not exist
	}

	/**
	 * Displays the cookie alert block.
	 */
	show() {

		// create cookie notice HTML elements
		var notice = h('div', 'cookie-notice'),
			text = hHTML('p', 'cookie-notice-text', this.message),
			dismissButton = h('button', 'cookie-notice-button--dismiss', 'Dismiss'),
			acceptButton = h('button', 'cookie-notice-button--accept button is-primary', 'Accept');

		// add elements to container
		notice.appendChild(text);
		notice.appendChild(dismissButton);
		notice.appendChild(acceptButton);

		// insert element as last child of body
		document.body.appendChild(notice);

		// when accept button is clicked
		acceptButton.addEventListener('click', (e) => {
			e.preventDefault();

			//set a cookie
			setCookie(this.options.cookieName, true, this.options.days)
            
			this.dismiss(notice);
		});

		// when notice is dismissed
		dismissButton.addEventListener('click', (e) => {
			e.preventDefault();
			this.dismiss(notice);
		});
	}

	/**
	 * Hids this cookie policy notice
	 * 
	 * @param {Element} notice the notice to hide
	 */
	dismiss(notice) {
		notice.classList.add('is-dismissed');
	}

}
