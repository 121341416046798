
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";

import 'swiper/css';
import "swiper/css/effect-coverflow";

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

export default class ActivitiesSwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.activities-swiper-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const activitiesSwiper = new Swiper('.activities-swiper', {
                effect: "coverflow",
                grabCursor: true,
                centeredSlides: true,
                slidesPerView: 2,
                initialSlide: 1,
                coverflowEffect: {
                    rotate: 35,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                },

                breakpoints: {
                    1025: {
                        effect: "coverflow",
                        grabCursor: true,
                        centeredSlides: true,
                        slidesPerView: 3,
                        initialSlide: 1,
                        coverflowEffect: {
                            rotate: 45,
                            stretch: 0,
                            depth: 150,
                            modifier: 1,
                            slideShadows: true,
                        },
                    } 
                 },

                pagination: {
                    el: ".swiper-pagination",
                },
            })
        })
    }
}